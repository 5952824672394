/**
 *
 * @returns {*}
 * @param data
 */
export function getByParams(data) {
    return axios({ url: '/api/insured/listByParams',
        method: 'post',
        data: data,
    });
}
